<template>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h1>Restore Schedule Status</h1>
      </div>
    </div>
    <div>
      <div v-if="loading"></div>
      <div v-else>
        <div class="row">
          <div class="clusterContainer col-12">
            <div class="row">
              <ClusterView v-for="workflow in workflows" :key="workflow.filename" :cluster="workflow" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          Visit
          <a href="https://viacomcbs.enterprise.slack.com/archives/C06NTNX1NF8">
            #pluto-mongo-tool-sync-coordination
          </a>
          for schedule discussion.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import cronParser from 'cron-parser';
import cronstrue from 'cronstrue';

import ClusterView from "@/components/ClusterView.vue";

export default {
  name: 'RestoreScheduleStatusPage',

  components: {
    ClusterView,
  },

  setup() {
    const workflows = ref([]);
    const loading = ref(true);

    const userTimezoneOffset = 0 - (new Date().getTimezoneOffset() / 60);

    const loadWorkflows = async () => {
      try {
        const response = await fetch('/workflows.json');
        const data = await response.json();
        workflows.value = data.map(workflow => ({
          name: workflow.name,
          filename: workflow.filename,
          schedules: workflow.schedules.map(schedule => ({
            expression: schedule,
            description: cronstrue.toString(
                schedule,
                {
                  tzOffset: userTimezoneOffset,
                }
            ),
            nextRun: getNextRun(schedule),
          })),
        }));
      } catch (err) {
        console.error(`Failed to load workflows: ${err}`);
      } finally {
        loading.value = false;
      }
    }

    const getNextRun = (expression) => {
      try {
        const interval = cronParser.parseExpression(expression, { utc: true });
        return interval.next().toString();
      } catch (err) {
        return `error parsing schedule: ${err.message}`;
      }
    }

    onMounted(loadWorkflows);

    return {
      workflows,
      loading,
    }
  }
}
</script>

<style>

</style>
