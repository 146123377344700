<template>
  <div class="col-md-6 col-sm-12 clusterOuter">
    <div class="row">
      <div class="col-12">
        <div class="clusterInner">
          <div class="row">
            <div class="col-12">
              <span v-if="cluster.schedules.length > 0" class="workflowStatus badge text-bg-success">Active</span>
              <span v-if="cluster.schedules.length === 0" class="workflowStatus badge text-bg-danger">Inactive</span>
              <h2>
                <a class="clusterName" :href="'https://github.com/Pluto-tv/tool-mongo-backup/blob/master/.github/workflows/' + cluster.filename" target="_blank">
                  {{ cluster.name }}
                </a>
              </h2>
            </div>
          </div>
          <div v-if="cluster.schedules.length > 0">
            <div v-for="schedule of cluster.schedules" :key="schedule">
              <div>
                <div><b>Next Run:</b></div>
                <div>{{ schedule.nextRun }}</div>
              </div>
              <div>
                <div><b>Schedule:</b></div>
                <div>
                  {{ schedule.description }}
                  <span class="scheduleExpression">
                    {{ schedule.expression }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['cluster'],
}
</script>

<style>
  .workflowStatus {
    float: right
  }

  .clusterOuter {
    //padding: 15px;
  }

  .clusterInner {
    margin: 5px;
    border: 1px solid #bbb;
    border-radius: 9px;
    background-color: white;
    padding: 5px;
  }

  .scheduleExpression {
    font-family: "Courier Prime", monospace;
    float: right;
  }

  .clusterName {
    color: black;
    text-decoration: none;
  }

  .clusterName:hover {
    text-decoration: underline;
  }
</style>
