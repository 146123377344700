<template>
  <RestoreScheduleStatusPage/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RestoreScheduleStatusPage from "@/components/RestoreScheduleStatus.vue";

export default defineComponent({
  name: 'App',
  components: {
    RestoreScheduleStatusPage,
  }
});
</script>

<style>
body {
  background-color: #eee;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
